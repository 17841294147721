body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 17px; 
}

.container {
  width: 100%;
  max-width: 800px;
  margin: 0px auto 32px auto;
}

.content {
  padding: 8px;
  margin: 8px;
}

.content h3 {
  text-align: center;
}

.button {
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.attachments {
  margin-top: 16px;
  display: flex; 
  flex-wrap: wrap;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
